var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"d-flex justify-space-between align-center mx-2"},[_c('page-header',{attrs:{"title":_vm.titlePage,"menu":_vm.menu}}),_c('internal-menu',{attrs:{"menuItems":_vm.menuItems}})],1),_c('v-snackbar',{attrs:{"bottom":_vm.snackbar.y === 'bottom',"color":_vm.snackbar.color,"left":_vm.snackbar.x === 'left',"multi-line":_vm.snackbar.mode === 'multi-line',"right":_vm.snackbar.x === 'right',"timeout":_vm.snackbar.timeout,"top":_vm.snackbar.y === 'top',"vertical":_vm.snackbar.mode === 'vertical'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar.opened = false}}},'v-btn',attrs,false),[_vm._v("Ok")])]}}]),model:{value:(_vm.snackbar.opened),callback:function ($$v) {_vm.$set(_vm.snackbar, "opened", $$v)},expression:"snackbar.opened"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"elevation":"0","height":"80"}},[_c('v-row',{staticClass:"d-flex justify-space-around align-center"},[_c('v-col',{attrs:{"cols":"8","md":"4"}},[_c('v-text-field',{attrs:{"filled":"","outlined":"","rounded":"","append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"width":"80%","color":"#2ca01c","dark":""},on:{"click":function($event){_vm.openDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus-thick")]),_vm._v(" Nova Conta")],1),_c('v-btn',{staticClass:"hidden-md-and-up",attrs:{"dark":"","color":"#2ca01c"},on:{"click":function($event){_vm.openDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus-thick")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.accounts,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}},[_c('v-card',[_c('v-toolbar',{staticStyle:{"padding-right":"10px !important"},attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',{staticClass:"overline"},[_vm._v("Conta Financeira")]),_c('v-btn',{attrs:{"icon":"","right":"","absolute":"","dark":""}},[_c('v-icon',{on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Nome"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Número"},model:{value:(_vm.identifier),callback:function ($$v) {_vm.identifier=$$v},expression:"identifier"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"outlined":"","items":[
                    'CAIXA',
                    'FORNECEDOR',
                    'CLIENTE',
                    'FUNCIONARIO',
                    'BANCARIA',
                    'FISCAL' ],"label":"Tipo"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),(_vm.type === 'BANCARIA')?_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.banks,"item-text":"label","item-value":"value","outlined":"","label":"Banco"},model:{value:(_vm.bank),callback:function ($$v) {_vm.bank=$$v},expression:"bank"}})],1):_vm._e(),(_vm.type === 'BANCARIA')?_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Agencia"},model:{value:(_vm.agency),callback:function ($$v) {_vm.agency=$$v},expression:"agency"}})],1):_vm._e(),(_vm.type === 'BANCARIA')?_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Conta Bancária"},model:{value:(_vm.accountNumber),callback:function ($$v) {_vm.accountNumber=$$v},expression:"accountNumber"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Descrição","required":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loadingAction,"color":"success","width":"200px","disabled":!_vm.name || !_vm.type},on:{"click":function($event){return _vm.registerOrUpdate()}}},[_vm._v(" Salvar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }